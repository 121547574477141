import { Global } from '@emotion/react';
import { FC } from 'react';

import Normalize from '../Normalize/Normalize';

const GlobalStyles: FC = () => (
  <>
    <Normalize />
    <Global
      styles={(theme) => ({
        html: {
          boxSizing: 'border-box',
          backgroundColor: theme.colors.neutral[50],
          color: theme.colors.black,
          fontSize: theme.fontSizes.normal,
          fontFamily: theme.fonts.sans,
          fontWeight: theme.fontWeights.regular,
          lineHeight: theme.lineHeights.normal,
        },
        '*, *:before, *:after': {
          boxSizing: 'inherit',
        },
      })}
    />
  </>
);

export default GlobalStyles;

import { useTheme } from '@emotion/react';
import { SpaceValue } from '@formic/styles';
import type * as CSS from 'csstype';
import { ForwardedRef, forwardRef } from 'react';

import Box, { BoxProps } from '../Box/Box';

export interface FlexProps extends Omit<BoxProps, 'display'> {
  center?: boolean;
  inline?: boolean;
  align?: CSS.Property.AlignItems;
  justify?: CSS.Property.JustifyContent;
  gap?: SpaceValue;
  columnGap?: SpaceValue;
  rowGap?: SpaceValue;
}

const Flex = forwardRef<HTMLElement, FlexProps>(function Flex(
  {
    center,
    inline,
    align = center ? 'center' : undefined,
    justify = center ? 'center' : undefined,
    gap,
    rowGap = gap,
    columnGap = gap,
    ...props
  },
  ref,
) {
  const theme = useTheme();
  const rowSpacing = (rowGap != null && theme.spacing(rowGap)) || undefined;
  const columnSpacing = (columnGap != null && theme.spacing(columnGap)) || undefined;

  return (
    <Box
      ref={ref as ForwardedRef<HTMLDivElement>}
      my={rowSpacing && `calc(${rowSpacing} / -2)`}
      mx={columnSpacing && `calc(${columnSpacing} / -2)`}
      css={[
        rowGap != null &&
          ((theme) => ({
            '& > *': {
              marginTop: `calc(${theme.spacing(rowGap)} / 2)  !important`,
              marginBottom: `calc(${theme.spacing(rowGap)} / 2) !important`,
            },
          })),
        columnGap != null &&
          ((theme) => ({
            '& > *': {
              marginLeft: `calc(${theme.spacing(columnGap)} / 2)  !important`,
              marginRight: `calc(${theme.spacing(columnGap)} / 2) !important`,
            },
          })),
      ]}
      display={inline ? 'inline-flex' : 'flex'}
      alignItems={align}
      justifyContent={justify}
      {...props}
    />
  );
});

export default Flex;

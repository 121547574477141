declare global {
  interface Window {
    __ENV__?: EnvInput;
  }
}

export type EnvInput = Record<string, string | undefined>;

export type Env<TPrefix extends string, TVars extends string> = {
  [K in `${TPrefix}${TVars}`]: string;
};

export interface VariableDefinition<T extends string> {
  name: T;
  defaultValue: string;
}

/**
 *
 * @param prefix The prefix applied to each variable at runtime.
 * @param variableDefinitions Array of variable names and their default values.
 * @param [envInput=window.__ENV__] Object to read the variables from. Defaults to window.__ENV__.
 * @returns An object containing the variable names prefixed with the prefix and their resulting values.
 */
export const createEnv = <TPrefix extends string, TVariable extends string>(
  prefix: TPrefix,
  variableDefinitions: VariableDefinition<TVariable>[],
  envInput: EnvInput = window.__ENV__ || {},
): Env<TPrefix, TVariable> => {
  const elements = variableDefinitions.reduce<Env<TPrefix, TVariable>>(
    (env, { name, defaultValue }) => {
      const prefixedName = `${prefix}${name}` as const;
      env[prefixedName] = envInput[prefixedName] || defaultValue;
      return env;
    },
    {} as Env<TPrefix, TVariable>,
  );

  return elements;
};

import { keyframes } from '@emotion/react';
import { ColorVariantProps } from '@formic/styles';
import { forwardRef } from 'react';

import Box, { BoxProps } from '../Box/Box';

export interface SpinnerProps extends BoxProps, ColorVariantProps {}

const chase = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const chaseDot = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`;

const chaseDotBefore = keyframes`
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1.0);
  }
`;

const Spinner = forwardRef<HTMLElement, SpinnerProps>(function Spinner(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { color = 'primary', size, ...props },
  ref,
) {
  return (
    <Box
      ref={ref}
      data-testid="spinner"
      size={size}
      css={(theme) => ({
        display: 'inline-block',
        position: 'relative',
        top: '-2px',
        verticalAlign: 'middle',
        animation: `${chase} 2.5s infinite linear both`,

        '& > div': {
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          animation: `${chaseDot} 2s infinite ease-in-out both`,

          '&::before': {
            content: '""',
            display: 'block',
            width: '25%',
            height: '25%',
            backgroundColor: theme?.spinner?.backgroundColor,
            borderRadius: '100%',
            animation: `${chaseDotBefore} 2s infinite ease-in-out both`,
          },

          '&:nth-of-type(1)': {
            animationDelay: '-1.1s',
          },
          '&:nth-of-type(2)': {
            animationDelay: '-1s',
          },
          '&:nth-of-type(3)': {
            animationDelay: '-0.9s',
          },
          '&:nth-of-type(4)': {
            animationDelay: '-0.8s',
          },
          '&:nth-of-type(5)': {
            animationDelay: '-0.7s',
          },
          '&:nth-of-type(6)': {
            animationDelay: '-0.6s',
          },
          '&:nth-of-type(1)::before': {
            animationDelay: '-1.1s',
          },
          '&:nth-of-type(2)::before': {
            animationDelay: '-1s',
          },
          '&:nth-of-type(3)::before': {
            animationDelay: '-0.9s',
          },
          '&:nth-of-type(4)::before': {
            animationDelay: '-0.8s',
          },
          '&:nth-of-type(5)::before': {
            animationDelay: '-0.7s',
          },
          '&:nth-of-type(6)::before': {
            animationDelay: '-0.6s',
          },
        },
      })}
      {...props}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Box>
  );
});

export default Spinner;

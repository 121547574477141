import type { AuthService } from '@formic/auth/AuthService';

import env from './env';

let authService: AuthService;

export const getAuthService = async (): Promise<AuthService> => {
  if (!authService) {
    authService = await import('@formic/auth/AuthService').then(
      ({ AuthService }) =>
        new AuthService({
          authority: env.FUSION_OF_OIDC_AUTHORITY,
          clientId: env.FUSION_OF_OIDC_INSTANCE_ID,
          redirectUri: env.FUSION_OF_OIDC_REDIRECT_URI,
          postLogoutRedirectUri: env.FUSION_OF_OIDC_POST_LOGOUT_REDIRECT_URI,
          scopes: [`formic.api_${env.FUSION_OF_OIDC_API_INSTANCE_NAME}.readwrite`],
        }),
    );
  }

  return authService;
};

import { VariableDefinition } from './createEnv';

export type OIDCVariables =
  | 'OIDC_AUTHORITY'
  | 'OIDC_INSTANCE_ID'
  | 'OIDC_API_INSTANCE_NAME'
  | 'OIDC_REDIRECT_URI'
  | 'OIDC_POST_LOGOUT_REDIRECT_URI';

export const oidcPreset = (): VariableDefinition<OIDCVariables>[] => [
  { name: 'OIDC_AUTHORITY', defaultValue: 'https://localhost:7000' },
  { name: 'OIDC_INSTANCE_ID', defaultValue: 'dev' },
  { name: 'OIDC_API_INSTANCE_NAME', defaultValue: 'dev_api' },
  { name: 'OIDC_REDIRECT_URI', defaultValue: `${window.location.origin}/auth_callback` },
  { name: 'OIDC_POST_LOGOUT_REDIRECT_URI', defaultValue: `${window.location.origin}/` },
];

export type GraphQLVariables = 'GRAPHQL_URI';

export const graphqlPreset = (): VariableDefinition<GraphQLVariables>[] => [
  { name: 'GRAPHQL_URI', defaultValue: 'https://localhost:5000/graphql' },
];

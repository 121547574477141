import { forwardRef } from 'react';

import Box, { BoxProps } from '../Box/Box';

// const defaultSpacing = 3;

export interface CardProps extends BoxProps {
  elevation?: number;
}

const Card = forwardRef<HTMLElement, CardProps>(function Card({ elevation = 1, ...props }, ref) {
  return (
    <Box
      ref={ref}
      css={(theme) => ({
        borderRadius: theme.radii[1],
        boxShadow: theme.shadows[elevation],
        //backgroundColor: '#fff',
      })}
      {...props}
    />
  );
});

export default Card;

/* export const CardMedia = styled<'figure', LayoutProps>('figure')`
  display: block;
  margin: -${spacing(defaultSpacing)} -${spacing(defaultSpacing)} ${spacing(defaultSpacing)};
  ${layout}

  img {
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    object-fit: cover;
    margin: -1px;
    padding: 1px;
    position: relative;
    top: -1px;
    border-radius: ${radius(1)} ${radius(1)} 0 0;
  }
`;

export const CardHeading = styled(Heading)`
  &:first-of-type {
    margin-top: 0;
  }
`;

export const CardContent = Box.withComponent('div');

export const CardActions = styled(Box)`
  background-color: ${colors.palette.neutral(100)};
  border-top: 1px solid ${colors.palette.neutral(200)};
  display: flex;
`; */
